















































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import GoalDistributionViewModel
  from
  '@/vue-app/view-models/components/goals-dashboard/contribute-goals/goal-distribution-view-model';

@Component({
  name: 'GoalDistribution',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class GoalDistribution extends Vue {
  @PropSync('specialDeposit', { type: Boolean, required: false, default: false })
  special_deposit!: boolean;

  goal_distribution_view_model = Vue.observable(
    new GoalDistributionViewModel(this),
  );

  @Watch('goal_distribution_view_model.wealth_flexible_deposit_state_manager.state.deposit.amount')
  onAmountChange(new_amount: string) {
    this.goal_distribution_view_model.setDepositAmount(new_amount);
  }
}
