import TYPES from '@/types';
import Vue from 'vue';

import { v4 } from 'uuid';

// Application
import LinkedGoalStateManagerService
  from '@/modules/flagship/investor-goal/linked-investor-goal/application/services/linked-goal-state-manager-service';
import GetInvestmentProductFundTypeQuery
  from '@/modules/flagship/catalogs/investment-product-fund-type/application/queries/get-investment-product-fund-type-query';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Domain
import {
  InvestorGoalScheduledDistributionWealthFlexibleDto,
} from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/flexible/domain/dto/investor-goal-scheduled-distribution-wealth-flexible-dto';
import LinkedGoalState
  from '@/modules/flagship/investor-goal/linked-investor-goal/domain/state/linked-goal-state';
import {
  WealthFlexibleDepositStateManager,
} from '@/modules/my-investment/allianz/transaction/domain/state/wealth-flexible-deposit-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class GoalDistributionViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.GET_INVESTMENT_PRODUCT_FUND_TYPE_QUERY)
  private readonly get_investment_product_fund_type!:
    GetInvestmentProductFundTypeQuery;

  @Inject(TYPES.LINKED_GOAL_STATE_MANAGER_SERVICE)
  private readonly linked_goal_state_manager_service!: LinkedGoalStateManagerService;

  @Inject(TYPES.WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER)
  private readonly wealth_flexible_deposit_state_manager!: WealthFlexibleDepositStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.contribute-goals.goal-distribution';

  readonly bucket_url = `${process.env.VUE_APP_S3_PUBLIC_URL}/investment/flagship/custom_investor_icons/`;

  readonly view: Vue;

  state: LinkedGoalState;

  schedule_distribution_id = v4();

  payload_to_save_distribution: InvestorGoalScheduledDistributionWealthFlexibleDto = {
    total_contribution: 0,
    unassigned_balance: 0,
    is_deposit: true,
    investor_goal_scheduled_distributions: [],
  };

  investment_product_fund_type_id = '';

  custom_goal_updated_at_time_stamp = new Date().getTime();

  input_rules = {
    amount: [
      requiredRule,
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) >= 500
        || this.translate_errors('utils.form-rules.minimum_error', { value: '$500.00 MXN' })
      ),
    ],
  };

  constructor(view: Vue, state?: LinkedGoalState) {
    this.view = view;
    this.state = state || this.linked_goal_state_manager_service.getLinkedGoalState();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  get icon_path() {
    this.state.is_loading = true;
    let icon = '';
    if (this.state.goal.goal_icon === 'icon-add.svg' && this.state.goal.custom_icon_file_id) {
      icon = `${this.bucket_url}${this.state.goal.custom_icon_file_id}?${this.custom_goal_updated_at_time_stamp}`;
    } else {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
      icon = require(`@/assets/icons/custom-goal-icons/${this.state.goal.goal_icon}`);
    }
    this.state.is_loading = false;
    return icon;
  }

  getInvestmentProductFundType = async () => {
    this.state.is_loading = true;
    try {
      const product_fund_types = await this
        .get_investment_product_fund_type.execute();
      const investment_product_fund_type = product_fund_types
        .find((product_fund_type) => product_fund_type.label === 'SWSRFP'
          && product_fund_type.investment_product_id === this.state.goal.associated_product_id);
      if (investment_product_fund_type) {
        this.investment_product_fund_type_id = investment_product_fund_type.id;
      }
    } catch {
      this.message_notifier.showErrorNotification(
        this.translate('errors.investment_product_fund_type'),
      );
    } finally {
      this.state.is_loading = false;
    }
  }

  nextStep = async () => {
    await this.getInvestmentProductFundType();
    const contributed_amount = parseFloat(
      this.state.goal.contributed_amount.replaceAll(/[^0-9]/g, ''),
    );
    this.payload_to_save_distribution.total_contribution = contributed_amount;
    this.payload_to_save_distribution.investor_goal_scheduled_distributions.push(
      {
        id: this.schedule_distribution_id,
        investor_goal_id: this.state.goal.investor_goal_id,
        investment_product_fund_type_id: this.investment_product_fund_type_id,
        amount_to_distribute: contributed_amount,
      },
    );
    this.wealth_flexible_deposit_state_manager.patch(
      { goal_distribution: this.payload_to_save_distribution },
    );
    this.view.$emit('nextStep');
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  setDepositAmount = (amount: string) => {
    if (amount !== '') {
      this.state.goal.contributed_amount = amount;
    }
  }
}
